
<template>
  <div class="warn_pop popView">
    <div class="main">
      <img class="close" @click="$emit('close')" src="@/assets/close.png" />
      <div class="title">
        <img class="icon" src="@/assets//warnIcon.png" />提示
      </div>
      <div class="content">请使用安卓手机端下载</div>
      <div class="confirm" @click="$emit('close')">确认</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "WarnBargain",
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.warn_pop{
  .close{
    position: absolute;
    width:21px;
    height: 21px;
    top: -60px;
    right: 0;
  }
  .main{
    position: relative;
    width: 260px;
    padding: 15px 15px;
    background: #FFFFFF;
    border-radius: 8px;
    .title{
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 26px;
      text-align: center;
      img{
        width: 26px;
        height: 26px;
        vertical-align: middle;
        margin-right: 2px;
      }
    }
    .content{
      margin-top: 20px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #666666;
      line-height: 20px;
      letter-spacing: 1px;
      text-align: center;
    }
    .confirm{
      width: 230px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      margin: 20px auto 0;
      background: #FB5957;
      border-radius: 8px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      cursor: pointer;
    }
  }
}
</style>
